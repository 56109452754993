import ArticleCard from './ArticleCard';

const Articles = ({ articles }) => {
    return (
        <div className="grid md:grid-cols-3 gap-[30px]">
            {
                articles.map((article) => (
                    <ArticleCard {...article} key={article.slug} />
                ))
            }
        </div>
    )
}

export default Articles;