import arrowUp from '../../../assets/icons/arrow-up.png';
import arrowDown from '../../../assets/icons/arrow-down.png';
import DotsIndicator from './DotsIndicator';

const SlideNavigator = ({ currentIndex, slides, arrowHandler, dotHandler }) => {
    return (
        <div className="absolute z-50 -rotate-90 md:rotate-0 -bottom-10 left-1/2 -translate-x-1/2 md:bottom-auto md:left-auto md:top-1/2 md:right-10 -translate-y-1/2">
            <img src={arrowUp} alt=" " className="cursor-pointer" onClick={() => arrowHandler(-1)} />
            <DotsIndicator slides={slides} currentIndex={currentIndex} dotHandler={dotHandler} />
            <img src={arrowDown} alt=" " className="cursor-pointer" onClick={() => arrowHandler(1)} />
        </div>
    )
}

export default SlideNavigator;