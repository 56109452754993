import Home from './Home';
import ProductService from './ProductService';
import About from './About';
import Schedule from './Schedule';
import Blog from './Blog';
import Contact from './Contact';
import Gallery from './Gallery';
import ProductDetail from './ProductDetail';
import BlogDetail from './BlogDetail';
import PrivacyPolicy from './PrivacyPolicy';

export {
    Home, ProductService, About, Schedule, Blog, Contact, Gallery, ProductDetail, BlogDetail, PrivacyPolicy
};