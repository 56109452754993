import TestimoniCard from "./TestimoniCard"

const Testimonials = ({ testimonis, currentPage, lastPage, moveHandler }) => {
    return (
        <div className="mb-[120px]">
            <h2 className="text-center text-[40px] mb-10 md:mb-[64px] font-bold">Testimoni</h2>
            <div className="grid md:grid-cols-2 gap-5 md:gap-10">
                {
                    testimonis.map((testimoni, index) => (
                        <TestimoniCard {...testimoni} key={`testimoni-${index}`} />
                    ))
                }
            </div>
            <div className="w-full flex items-center justify-center mt-3">
                <button className="w-10 h-10 border rounded-lg" onClick={() => moveHandler(-1)}>{`<`}</button>
                <div className="mx-3">{currentPage} / {lastPage}</div>
                <button className="w-10 h-10 border rounded-lg" onClick={() => moveHandler(1)}>{`>`}</button>
            </div>
        </div>
    )
}

export default Testimonials;