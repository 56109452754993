import { About, Blog, Home, ProductService, Schedule, Contact, Gallery, ProductDetail, BlogDetail, PrivacyPolicy } from "../pages";

const ROUTES = [
    {link: '/', text: 'Home', element: <Home />, hidden: false},
    {link: '/tentang', text: 'Tentang', element: <About />, hidden: false},
    {link: '/produk', text: 'Produk & Layanan', element: <ProductService />, hidden: false},
    {link: '/keberangkatan', text: 'Keberangkatan', element: <Schedule />, hidden: false},
    {link: '/blog', text: 'Blog', element: <Blog />, hidden: false },
    {link: '/galleries', text: 'Gallery', element: <Gallery />, hidden: false },
    {link: '/kontak', text: 'Kontak', element: <Contact />, hidden: false },
    {link: '/produk/:slug', text: '', element: <ProductDetail />, hidden: true},
    {link: '/blog/:slug', text: '', element: <BlogDetail />, hidden: true},
    {link: '/kebijakan-privasi', text: '', element: <PrivacyPolicy />, hidden: true},
];

export default ROUTES;