import facebookIcon from '../../../assets/icons/facebook.png';
import instagramIcon from '../../../assets/icons/instagram.png';
import twitterIcon from '../../../assets/icons/twitter.png';
import youtubeIcon from '../../../assets/icons/youtube-brands.svg';
import tiktokIcon from '../../../assets/icons/tiktok-brands.svg';

const FooterDescription = ({ logo, shortDescription, facebook, instagram, twitter, youtube, tiktok }) => {

    return (
        <div className="max-w-[350px] mb-10 md:m-0">
            <img src={logo} alt="logo" className="object-contain w-[150px]" />
            <p className="text-white text-[14px] mt-[16px] mb-[24px] grayscale">{shortDescription}</p>
            <div className="flex items-center">
                <a href={instagram} target="_blank" rel="noreferrer" >
                    <img src={instagramIcon} alt=" " />
                </a>
                <a href={twitter} target="_blank" rel="noreferrer" className="mx-3" >
                    <img src={twitterIcon} alt=" " />
                </a>
                <a href={facebook} target="_blank" rel="noreferrer" >
                    <img src={facebookIcon} alt=" " />
                </a>
                <a href={youtube} target="_blank" rel="noreferrer" >
                    <img src={youtubeIcon} className="text-white mx-3 w-8 h-6" alt=" " />
                </a>
                <a href={tiktok} target="_blank" rel="noreferrer" >
                    <img src={tiktokIcon} className="text-white w-8 h-6" alt=" " />
                </a>
            </div>
        </div>
    )
}

export default FooterDescription;