import arrowUpIcon from '../../assets/icons/arrow-up.png';

const Jumbotron = ({ title, background }) => {
    return (
        <div className="relative w-full h-[420px] bg-gradient-to-t from-[rgba(10,25,49,1)] to-[rgba(10,25,49,.75)] flex items-center justify-center text-center flex-col">
            <img src={background} alt="" className="w-full h-full object-cover absolute top-0 left-0" />
            <h1 className="text-4xl md:text-[56px] font-bold text-white mb-5">{title}</h1>
            <p className='flex font-bold text-[#808998]'>Home <img src={arrowUpIcon} alt=" " className='w-5 rotate-90 mx-3' /> <span className='text-white'>{title}</span></p>
        </div>
    )
}

export default Jumbotron;