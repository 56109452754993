const Legalities = ({ legalities }) => {
    return (
        <div className='py-[120px] bg-[#F6F9FF]'>
            <div className="grid md:grid-cols-3 container mx-auto px-4 md:px-0">
                {
                    legalities.map((legality, index) => (
                        <div className='mb-5' key={`legality-${index}`}>
                            <img src={legality.image} alt="" className='mb-3 rounded-[24px]' />
                            <p className='text-gray-600'>{legality.title}</p>
                            <h4 className="text-xl font-bold">{legality.number}</h4>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Legalities;