import { useEffect, useState } from 'react';
import { SettingApi } from '../../api';
import whatsappIcon from '../../assets/icons/whatsapp.png';

const FloatingButton = () => {
    const [whatsapp, setWhatsapp] = useState(0);

    useEffect(() => {
        SettingApi.get().then((res) => {
            let whatsapp = res.whatsapp;
            if (whatsapp[0] === '0') {
                whatsapp = '62' + whatsapp.substring(1);
            }
            setWhatsapp(whatsapp);
        }).catch(err => console.log(err))
    }, []);

    return (
        <a href={`https://wa.me/${whatsapp}`} target="_blank" rel="noreferrer" className='fixed z-50 right-10 bottom-10 flex bg-[#25D366] rounded-full px-5 py-3 text-white'>
            <img src={whatsappIcon} alt="" className='mr-2' />
            Hubungi Kami
        </a>
    )
}

export default FloatingButton;