import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductApi, SettingApi } from "../../api";
import { CKEditor, PriceCard } from "../../components";
import ProductImages from "../../components/ProductImages";
import locationIcon from '../../assets/icons/product/location-pin.png';
import calendarTimeIcon from '../../assets/icons/product/calendar-time.png';
import calendarIcon from '../../assets/icons/product/calendar.png';
import starIcon from '../../assets/icons/product/star.png';
import hotelIcon from '../../assets/icons/product/hotel.png';
import flightIcon from '../../assets/icons/product/flight.png';
import trainIcon from '../../assets/icons/product/train.png';
import yesIcon from '../../assets/icons/product/yes.png';
import noIcon from '../../assets/icons/product/no.png';

const ProductDetail = () => {
    const [selectedPackage, setSelectedPackage] = useState('');
    const {slug} = useParams();
    const [product, setProduct] = useState([]);
    const [whatsapp, setWhatsapp] = useState(0);

    useEffect(() => {
        SettingApi.get().then((res) => {
            let whatsapp = res.whatsapp;
            if (whatsapp[0] === '0') {
                whatsapp = '62' + whatsapp.substring(1);
            }
            setWhatsapp(whatsapp);
        }).catch(err => console.log(err))
        loadProduct();
    }, []);

    const loadProduct = () => {
        ProductApi.show(slug).then((res) => {
            setProduct(res.data);
        }).catch((error) => {
            throw error;
        });
    }

    const getTransportationIcon = (type) => {
        let icon;
        switch (type){
            case 'pesawat':
                icon = <img src={flightIcon} alt="" className="mr-2 w-[24px] h-[24px] object-cover" />
                break;
            case 'kereta':
                icon = <img src={trainIcon} alt="" className="mr-2 w-[24px] h-[24px] object-cover" />
                break;
            default:
                break;
        }

        return icon;
    }

    return (
        <div className='container mx-auto px-4 md:p-0 md:mt-[65px] mb-[120px]'>
            <div className="flex font-bold text-[#BBC1CC] mb-[40px]">
                Home / Produk & Layanan / <span className='text-[#336B89] ml-1'>{product.name}</span>
            </div>
            <ProductImages poster={product.poster} images={product.images} />
            <div className="grid md:grid-cols-12 gap-[30px] mt-[64px]">
                <div className="md:col-start-1 md:col-end-8">
                    <div className="flex flex-wrap items-center justify-between mb-10">
                        <div className="flex items-center w-auto mb-2 md">
                            <div className="w-[56px] h-[56px] rounded-lg mr-1">
                                <img src={locationIcon} alt="" className="w-full h-full object-cover" />
                            </div>
                            <div className="font-bold ml-2">
                                <span className="text-gray-600 uppercase text-[12px]">Destinasi</span>
                                <p className="text-bold text-sm text-[16px]">
                                    {product.destination?.map((destination, index) => (
                                        <span key={`destination-${index}`}>
                                        {product.destination.length < 2 ? destination.name : (index === product.destination.length - 1 ? ' & ' + destination.name : (index === product.destination.length - 2 ? destination.name : destination.name + ', '))}
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center w-auto mb-2 md">
                            <div className="w-[56px] h-[56px] rounded-lg mr-1">
                                <img src={calendarTimeIcon} alt="" className="w-full h-full object-cover" />
                            </div>
                            <div className="font-bold ml-2">
                                <span className="text-gray-600 uppercase text-[12px]">Durasi</span>
                                <p className="text-bold text-sm text-[16px]">
                                    {product.duration} Hari
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center w-auto mb-2 md">
                            <div className="w-[56px] h-[56px] rounded-lg mr-1">
                                <img src={calendarIcon} alt="" className="w-full h-full object-cover" />
                            </div>
                            <div className="font-bold ml-2">
                                <span className="text-gray-600 uppercase text-[12px]">Keberangkatan</span>
                                <p className="text-bold text-sm text-[16px]">
                                    {product.departure}
                                </p>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-[#0A1931] text-3xl font-bold">{product.name}</h2>
                    <CKEditor content={product.description} />
                    <div className="mb-5 mt-16">
                        <h3 className="text-bold text-xl mb-3 font-bold">Hotel</h3>
                        <div className="grid md:grid-cols-2 gap-3">
                            {
                                product.hotel?.map((hotel, index) => (
                                    <div className="font-bold" key={`hotel-${index}`}>
                                        <span className="text-gray-500 text-sm uppercase">Hotel {hotel.location}</span>
                                        <div className="flex items-center mt-2">
                                            <img src={hotelIcon} alt="" className="mr-2 w-[24px] h-[24px] object-cover" />
                                            <p>{hotel.name}</p>
                                            <img src={starIcon} alt="" className="mx-2 w-[16px] h-[16px] object-cover" />
                                            <p>{hotel.star}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-16">
                        <h3 className="text-bold text-xl mb-3 font-bold">Transportasi</h3>
                        <div className="grid md:grid-cols-2 gap-3">
                            {
                                product.transportations?.map((transportation, index) => (
                                    <div className="font-bold" key={`transportation-${index}`}>
                                        <span className="text-gray-500 text-sm uppercase">{transportation.type}</span>
                                        <div className="flex items-center mt-2">
                                            { getTransportationIcon(transportation.type) }
                                            <p>{transportation.service}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-16">
                        <h3 className="text-bold text-xl mb-3 font-bold">Termasuk & Tidak Termasuk</h3>
                        <div className="grid md:grid-cols-2 gap-3">
                            <ul>
                                {
                                    product.packages?.map((item, index) => (
                                        item.is_included ? (
                                            <li className="flex items-start mb-2" key={`included-package-${index}`}>
                                                <img src={yesIcon} alt="" className="mr-3" />
                                                {item.name}
                                            </li>
                                        ): null
                                    ))
                                }
                            </ul>
                            <ul>
                                {
                                    product.packages?.map((item, index) => (
                                        !item.is_included ? (
                                            <li className="flex items-start mb-2" key={`not-included-package-${index}`}>
                                                <img src={noIcon} alt="" className="mr-3" />
                                                {item.name}
                                            </li>
                                        ): null
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="mb-16">
                        <h3 className="text-bold text-xl mb-3 font-bold">Itinerary</h3>
                        <ul>
                            {
                                product.plans?.map((plan, index) => (
                                    <li className="flex mb-2" key={`plan-${index}`}>
                                        <div className="w-10 h-10 bg-[#FFBF00] text-black rounded-full flex items-center justify-center font-bold mr-3">
                                            {index + 1}
                                        </div>
                                        <div className="w-[90%]">
                                            <h4 className="font-bold mb-2">Hari ke-{plan.day} : {plan.title}</h4>
                                            <CKEditor content={plan.description} />
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-bold text-xl mb-3 font-bold">Syarat & Ketentuan</h3>
                        <CKEditor content={product.terms_and_conditions} />
                    </div>
                </div>
                <div className="md:col-start-9 md:col-end-13">
                    <PriceCard type="Quad" price={product.price?.quad} selectedPackage={selectedPackage} handler={() => setSelectedPackage("Quad")} />
                    <PriceCard type="Triple" price={product.price?.triple} selectedPackage={selectedPackage} handler={() => setSelectedPackage("Triple")} />
                    <PriceCard type="Double" price={product.price?.double} selectedPackage={selectedPackage} handler={() => setSelectedPackage("Double")} />
                    {
                        selectedPackage ? <a href={`https://wa.me/${whatsapp}?text=Assalamualaikum, Saya ingin mengetahui informasi lebih detail tentang ${product.name} - ${selectedPackage} bersama Sira Tour`} className="block text-center font-bold w-full py-[16px] rounded-[12px] bg-[#336B89] text-white disabled" target="_blank" rel="noreferrer">Pesan Sekarang</a> : null
                    }
                    <a href={product.brochure} className="block text-center font-bold w-full mt-[16px] py-[16px] rounded-[12px] text-blue-500" target="_blank" rel="noreferrer">Download Brosur</a>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;