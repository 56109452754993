import addressDark from '../assets/icons/address-dark.png';
import phoneDark from '../assets/icons/phone-dark.png';
import mailDark from '../assets/icons/mail-dark.png';
import timeDark from '../assets/icons/time-dark.png';

const CONTACTS = [
    {
        icon: addressDark,
        title: 'ALAMAT',
        description: 'Jl. Bambu Kuning Utara No.212, Bambu Apus, Cipayung, Jakarta Timur, DKI Jakarta',
    },
    {
        icon: phoneDark,
        title: 'TELEPON & WHATSAPP',
        description: '021 2345 6789 & +628 123 4567 890',
    },
    {
        icon: mailDark,
        title: 'EMAIL',
        description: 'info@siratour.co.id',
    },
    {
        icon: timeDark,
        title: 'JAM OPERASIONAL',
        description: 'Senin - Jumat, 9:00 - 17:00 WIB',
    },
];

export default CONTACTS;