import CONFIG from "../config";

const ProductApi = {
    endpoint: `${CONFIG.API_BASE_URL}/products`,
    async get (page){
        const options = {};
        const request = new Request(`${this.endpoint}?page=${page}`, options);
        const response = await fetch(request);
        const resJson = response.json();

        return resJson;
    },
    async show(slug){
        const options = {};
        const request = new Request(`${this.endpoint}/${slug}`, options);
        const response = await fetch(request);
        const resJson = response.json();

        return resJson;
    }
}

export default ProductApi;