import ContactCard from "./ContactCard";

const ContactList = ({contacts}) => {
    return (
        <div className="grid md:grid-cols-4 gap-4 my-[120px]">
            {
                contacts.map((contact, i) => (
                    <ContactCard {...contact} key={`contact-${i}`} />
                ))
            }
        </div>
    )   
}

export default ContactList;