import FooterDescription from "./FooterDescription";
import FooterMenu from "./FooterMenu";
import locationPinWhite from '../../assets/icons/location-pin-white.png';
import phone from '../../assets/icons/phone.png';
import time from '../../assets/icons/time.png';
import { SettingApi } from "../../api";
import { useEffect, useState } from "react";

const Footer = () => {
    const aboutMenu = [
        {text: 'Tentang', link: '/tentang'},
        {text: 'Keberangkatan', link: '/keberangkatan'},
        {text: 'Blog', link: '/blog'},
        {text: 'Kebijakan Privasi', link: '/kebijakan-privasi'},
        {text: 'Gallery', link: '/galleries'},
        {text: 'Flyer', link: '/'},
        {text: 'Kontak', link: '/kontak'},
    ];
    const tourMenu = [
        {text: 'Paket Haji', link: '/'},
        {text: 'Paket Umroh', link: '/'},
        {text: 'Paket Liburan', link: '/'},
    ];

    const [socialMedia, setSocialMedia] = useState({});
    const [contactMenu, setContactMenu] = useState([]);
    const [shortDescription, setShortDescription] = useState('');
    const [logo, setLogo] = useState('');

    useEffect(() => {
        SettingApi.get().then((res) => {
            setContactMenu([
                {text: res.address, link: false, icon: locationPinWhite},
                {text: `${res.whatsapp} & ${res.phone}`, link: false, icon: phone},
                {text: '09:00 - 18:00', link: false, icon: time},
            ]);
            setSocialMedia({
                facebook: res.facebook,
                instagram: res.instagram,
                twitter: res.twitter,
                youtube: res.youtube,
                tiktok: res.tiktok,
            });
            setShortDescription(res.short_description);
            setLogo(res.logo_light);
        }, []);
    },[]);

    return (
        <footer className="bg-[#0A1931]">
            <div className="container mx-auto px-4 md:px-0">
                <div className="md:flex py-[60px] md:justify-between">
                    <FooterDescription logo={logo} shortDescription={shortDescription} {...socialMedia} />
                    <FooterMenu title="Beranda" menu={aboutMenu} columns="2" />
                    <FooterMenu title="Paket Wisata" menu={tourMenu} columns="1" />
                    <FooterMenu title="Kontak" menu={contactMenu} columns="1" />
                </div>
                <div className="py-[30px] border-t border-gray-700 text-white text-center">
                    Copyright 2022 &copy; PT. Sira Wisata Dunia
                </div>
            </div>
        </footer>
    )
}

export default Footer;