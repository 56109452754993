import './style.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>
                Adanya Kebijakan Privasi ini adalah komitmen nyata dari SIRA untuk menghargai dan melindungi setiap data atau informasi pribadi Pengguna aplikasi SIRA.
            </p>
            <p>Dengan mengklik “Daftar” (Register) atau pernyataan serupa yang tersedia di laman pendaftaran Aplikasi, Pengguna menyatakan bahwa setiap Data Pribadi Pengguna merupakan data yang benar dan sah, Pengguna mengakui bahwa ia telah diberitahukan dan memahami ketentuan Kebijakan Privasi ini serta Pengguna memberikan persetujuan kepada SIRA untuk memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan data tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan Privasi.</p>
            <h2>1. DATA APA YANG KAMI KUMPULKAN</h2>
            <p>Saat menggunakan SIRA kami mungkin akan meminta anda untuk memberikan informasi data pribadi yang dapat digunakan untuk menghubungi atau mengidentifikasi diri anda dan untuk memberikan anda kepuasaan saat menggunakan aplikasi. Data pribadi yang kami kumpulkan meliputi, namun tidak terbatas pada:</p>
            <ul>
                <li>Data Pribadi pengguna yang anda input atau unduh ke SIRA: nama, alamat, tanggal lahir, rancangan, desain, teks, gambar grafis, foto, gambar, citra, video, perangkat lunak, musik, suara dan file lain, peringkat kredit, tarif, biaya, kuotasi, data historis, grafik, statistik, artikel, informasi kontak kami, setiap informasi lain, beserta pemilihan dan pengaturannya;</li>
                <li>Data Kontak yang juga termasuk Nomor telepon, Alamat E-mail, Alamat Pengiriman;</li>
                <li>Informasi yang terdapat dalam pada pesan yang anda kirimkan ke kami: komentar, permohonan, pertanyaan, keluhan;</li>
                <li>Lokasi geografis terkini anda;</li>
                <li>Alamat IP anda;</li>
                <li>Tanggal dan waktu anda mengakses layanan kami, perangkat keras, perangkat lunak, atau peramban internet yang anda gunakan untuk mengakses SIRA, dan informasi mengenai sistem pengoperasian aplikasi dan pengaturan bahasa; dan</li>
                <li>Informasi yang anda kirimkan ke aplikasi mobile kami untuk menginformasikan anda mengenai inisiatif pengajuan, konten, survey dan promosi lainnya yang ditawarkan oleh kami.</li>
            </ul>
            <h2>2. BAGAIMANA KAMI MENGGUNAKAN DATA TERSEBUT</h2>
            <p>Kami menggunakan data pribadi untuk memberikan anda berbagai layanan, penawaran terbaru dan untuk melindungi SIRA dan pelanggan kami seperti yang disebutkan di bawah. Dalam kasus tertentu, kami akan meminta anda untuk memberikan data anda secara langsung. Jika anda tidak dapat memberikan data yang kami minta atau menolak kami untuk mengumpulkan data tersebut, kami mungkin tidak dapat memberikan layanan yang anda minta. Kami menggunakan data pribadi untuk kebutuhan bisnis kami, yang meliputi:</p>
            <ul>
                <li>Untuk memberikan layanan yang anda minta;</li>
                <li>Memproses transaksi anda yang dikirimkan;</li>
                <li>Agar anda dapat mengumpulkan dan melihat informasi transaksi anda;</li>
                <li>Merespon komentar, permintaan dan pertanyaan anda;</li>
                <li>Menghubungi anda dan memberikan informasi mengenai penawaran spesial atau promosi yang kami percaya akan menarik untuk anda;</li>
                <li>Memberi notifikasi mengenai penawaran produk atau layanan yang mungkin menarik untuk anda;</li>
                <li>Secara umum mengatur dan menjalankan bisnis kami;</li>
                <li>Menyediakan, mengembangkan dan menjaga performa pelayanan pelanggan dan produk kami;</li>
                <li>Mencegah anda dari segala bentuk penipuan; dan </li>
                <li>Mematuhi hukum dan regulasi yang berlaku</li>
            </ul>
            <p>Kami juga menggunakan informasi yang kami terima dalam format agregat dan anonim untuk menghasilkan laporan trend dan statistik, seperti trend pencarian pada perangkat mobile, tingkat email yang dibuka pada tiap industri, pelaksanaan kampanye yang terbaik, atau jumlah pengguna yang telah diketahui, atau yang telah diklik, pada aplikasi kami atau mengevaluasi pembelian produk dan layanan kami. Informasi pembayaran yang anda berikan pada kami, hanya akan digunakan untuk fasilitas pembayaran produk atau layanan kami.</p>
            <h2>3. PENYIMPANAN DATA PRIBADI ANDA</h2>
            <p>Anda berhak untuk mengakses, memperbaharui, mengganti, mengoreksi atau menghapus data pribadi dan pilihan anda kapanpun, Kami akan menyimpan data pribadi anda selama</p>
            <ul>
                <li>Akun anda aktif;</li>
                <li>Data pribadi anda diperlukan untuk menyediakan jasa kepada anda; atau</li>
                <li>Diperlukan untuk kebutuhan legal, regulasi, asuransi dan audit</li>
            </ul>
            <p>Jika anda ingin menghapus akun atau meminta kami untuk tidak lagi menggunakan informasi anda untuk kebutuhan pelayanan, hubungi kami di <a href="mailto:admin@SIRA.co.id" class="font-bold">admin@SIRA.co.id</a></p>
            <p>Dalam permohonan anda, mohon dijelaskan data pribadi apa yang ingin anda ubah, apakah anda ingin data pribadi anda disembunyikan dari database kami atau ada batasan lain yang ingin anda terapkan pada penggunaan data pribadi anda. Untuk keamanan anda, kami hanya memenuhi permohonan anda untuk data pribadi yang berhubungan dengan spesifik alamat email yang anda gunakan untuk mengirimkan permohonan anda, dan kami kemungkinan akan perlu memverifikasi identitas anda sebelum memenuhi permohonan anda. Kami akan mencoba untuk memenuhi permohonan anda sesegera mungkin.</p>
            <p>Mohon diingat bahwa kami mungkin akan perlu menyimpan beberapa informasi untuk kepentingan penyimpanan data dan/atau untuk menyelesaikan transaksi yang telah anda lakukan sebelum anda mengajukan perubahan atau penghapusan (contoh, ketika anda mengajukan atau membuat permintaan atau mengikuti promosi, anda mungkin tidak bisa mengubah atau menghapus data pribadi anda, dapat diberikan sampai setelah transaksi atau promosi telah selesai). Kemungkinan juga akan ada informasi sisa yang akan tetap disimpan di database kamu dan tempat penyimpanan lainnya, yang tidak akan dihapus. Sebagai tambahan, mungkin akan ada beberapa informasi yang tidak dapat kami izinkan untuk anda ulas, untuk kepentingan legal, keamanan, dan beberapa alasan lainnya.</p>
            <h2>4. BERAPA LAMA KAMI MENYIMPAN DATA</h2>
            <p>Kami menyimpan data pribadi anda yang kami kumpulkan di mana kami memiliki keperluan untuk izin legalitas bisnis yang sedang berlangsung (contoh, untuk memberikan anda layanan yang anda minta atau untuk memenuhi hukum yang berlaku, pajak, atau kebutuhan akunting) atau selama dibutuhkan untuk tujuan yang dideskripsikan pada ketentuan data privasi ini.</p>
            <h2>5. KEPADA SIAPA DAN DI MANA DATA PRIBADI ANDA DIBUKA</h2>
            <p>Data pribadi anda, untuk tujuan yang telah disebutkan di atas, akan ditransfer atau dibuka ke semua perusahaan yang terdapat di SIRA dan perusahaan yang terafiliasi/berhubungan dengan ('SIRA') yang berlokasi di Indonesia.</p>

            <p>Kami akan membuka data pribadi anda agar dapat mematuhi hukum dan regulasi yang berlaku di Indonesia hanya dengan ketentuan sebagai berikut:</p>
            <ul>
                <li>Kepada semua partner pihak ketiga dari membership program, agar dapat memberikan produk dan layanan kepada anda dan menjalankan transaksi yang anda lakukan;</li>
                <li>Kepada semua perusahaan pihak ketiga yang menyediakan layanan informasi teknologi kepada kami;</li>
                <li>Kepada semua penyedia layanan pihak ketiga, agar dapat mengirimkan kode OTP ke nomor telepon anda pada proses registrasi;</li>
                <li>Kepada semua pihak ketiga yang kami anggap diperlukan atau pantas untuk mencegah, mengurangi atau menindaklanjuti segala aktivitas penipuan; dan</li>
                <li>Segala kondisi lain yang membutuhkan izin anda lebih lanjut.</li>
            </ul>
            <h2>6. PERSETUJUAN</h2>
            <p>Dengan memberikan data pribadi anda, anda secara sukarela telah setuju, menjadi subjek pemberitahuan seperti yang disampaikan di bawah, kepada SIRA menggunakan dan memproses data pribadi anda untuk layanan yang disampaikan pada halaman ini dan, jika diperlukan, memindahkan data pribadi anda, untuk melayani anda pada SIRA dimanapun berada dari waktu ke waktu.</p>
            <p>Dengan menerima persetujuan ini, anda telah setuju untuk mengikuti semua regulasi yang tercantum pada ketentuan data privasi ini sebelum menggunakan layanan yang ditawarkan pada aplikasi.</p>
            <p>Dalam keadaan di mana anda secara sukarela membagikan data anda ke individu perorangan lainnya, anda dianggap telah memberikan informasi tersebut dengan persetujuan anda. Permintaan untuk membatalkan ketentuan data privasi ini akan dianggap sebagai permintaan untuk menutup akun anda, sehingga anda tidak lagi dapat mengakses akun anda.</p>
            <h2>7. PENOLAKAN JAMINAN DAN BATASAN TANGGUNG JAWAB</h2>
            <p>SIRA adalah suatu perusahaan aplikasi yang menyediakan layanan kepada Pengguna. Dengan demikian transaksi yang terjadi adalah transaksi antar Pengguna, sehingga Pengguna memahami bahwa batasan tanggung jawab SIRA secara proporsional. SIRA selalu berupaya untuk menjaga aplikasi aman, nyaman, dan berfungsi dengan baik, tapi SIRA tidak dapat menjamin operasi terus-menerus atau akses ke Aplikasi dapat selalu sempurna. Informasi dan data dalam Aplikasi memiliki kemungkinan tidak terjadi secara realtime.</p>
            <p>Pengguna setuju bahwa memanfaatkan Aplikasi atas risiko Pengguna sendiri, dan Aplikasi diberikan kepada Pengguna pada "SEBAGAIMANA ADANYA" dan "SEBAGAIMANA TERSEDIA".</p>
            <p>Sejauh diizinkan oleh hukum yang berlaku, SIRA (termasuk Induk Perusahaan, direktur, dan karyawan) adalah tidak bertanggung jawab, dan Pengguna setuju untuk tidak menuntut Sira Tour bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari:</p>
            <ul>
                <li>Penggunaan atau ketidakmampuan Pengguna dalam menggunakan Aplikasi</li>
                <li>Harga, pengiriman atau petunjuk lain yang tersedia dalam Aplikasi</li>
                <li>Keterlambatan atau gangguan dalam Aplikasi</li>
                <li>Kelalaian dan kerugian yang ditimbulkan oleh Pengguna</li>
                <li>Kualitas Barang Fisik</li>
                <li>Pengiriman Barang Fisik</li>
                <li>Pelanggaran Hak atas Kekayaan Intelektual</li>
                <li>Perselisihan antar Pengguna</li>
                <li>Pencemaran nama baik pihak lain</li>
                <li>Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam Aplikasi</li>
                <li>Tindakan penegakan yang diambil sehubungan dengan akun Pengguna</li>
                <li>Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun Pengguna.</li>
            </ul>
            <h2>8. PEMBARUAN KETENTUAN DATA PRIVASI</h2>
            <p>Kami mungkin akan memperbarui atau mengganti ketentuan data privasi ini dari waktu ke waktu, untuk menaati hukum atau menaati kebutuhan bisnis kami yang mungkin akan berganti. Ketika kami memperbarui ketentuan data privasi, kami juga akan memberitahukan anda, secara konsisten, pembaharuan yang kami lakukan. Setiap pembaruan akan kami tampilkan pada aplikasi kami. Dengan mengakses aplikasi kami, akses anda dan penggunaan pada aplikasi kami akan menjadi subjek terhadap pembaruan dan perubahan.</p>

            <h2>9. INFORMASI UMUM</h2>
            <p>Untuk tujuan pemberitahuan, SIRA termasuk anak perusahaan langsung maupun tidak langsung dan perusahaan yang berhubungan dengan SIRA TOUR dari waktu ke waktu dan dimanapun di Indonesia.</p>
        </div>
    );
}

export default PrivacyPolicy;