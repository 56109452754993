import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticleApi from "../../api/ArticleApi";
import { CKEditor } from "../../components";
import facebookIcon from '../../assets/icons/Social Media/Facebook.png';
import twitterIcon from '../../assets/icons/Social Media/Twitter.png';
import telegramIcon from '../../assets/icons/Social Media/Telegram.png';
import {FacebookShareButton,TelegramShareButton,TwitterShareButton} from 'react-share';

const BlogDetail = () => {
    const {slug} = useParams();

    const [article, setArticle] = useState({});

    useEffect(() => {
        loadArticle();
    }, []);

    const loadArticle = () => {
        ArticleApi.getBySlug(slug).then((res) => {
            setArticle(res.data);
        }).catch((error) => {
            throw error;
        });
    }

    return (
        <div className="md:mt-[65px] mb-[120px]">
            <div className="max-w-[730px] mx-auto px-4 md:p-0">
                <div className="flex font-bold text-[#BBC1CC] flex-wrap">
                    Home/Blog/<span className='text-[#336B89]'>{article.title}</span>
                </div>
                <img src={article.cover} alt="" className="w-full max-h-[300px] md:max-h-[500px] object-cover my-[40px] rounded-lg" />
                <CKEditor content={article.description} />
                <div className="flex md:items-center flex-col md:flex-row md:justify-between space-y-3 mt-[65px]">
                    <div className="flex items-center space-x-3">
                        <p>Tag:</p>
                        {
                            article.tags?.map((tag, index) => (
                                <span className="py-1 px-3 rounded-full bg-gray-200" key={`tag-${index}`}>{tag.name}</span>
                            ))
                        }
                    </div>
                    <div className="flex items-center space-x-3">
                        <p>Share:</p>
                        <FacebookShareButton url={window.location.href} quote={article.title}>
                            <img src={facebookIcon} alt="" />
                        </FacebookShareButton>
                        <TelegramShareButton title={article.title} url={window.location.href}>
                            <img src={telegramIcon} alt="" />
                        </TelegramShareButton>
                        <TwitterShareButton title={article.title} url={window.location.href}>
                            <img src={twitterIcon} alt="" />
                        </TwitterShareButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetail;