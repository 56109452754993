import { useState } from "react";

const ProductImages = ({ poster, images }) => {
    const [activeImage, setActiveImage] = useState(0);

    const closeHandler = () => {
        setActiveImage('');
    }

    const getImage = (n) => {
        if (n === 1) return poster;

        return images[n - 2].url;
    }

    const moveActiveImage = (n) => {
        const moveTo = activeImage + n;

        if (moveTo < 1) {
            setActiveImage(images.length + 1);
        } else if (moveTo > images.length + 1) {
            setActiveImage(1);
        } else {
            setActiveImage(moveTo);
        }
    }

    return (
        <>
            {
                activeImage ? (
                    <div className="fixed top-0 left-0 w-full h-full bg-[rgba(10,25,49,.75)] z-[100]">
                        <div className="z-[100] relative w-full h-full p-4 md:p-[80px]">
                            <img src={getImage(activeImage)} alt="" className="w-full h-full rounded-lg object-contain" />
                            
                            <div className="z-[100] w-10 h-10 bg-red-400 fixed top-10 right-10 cursor-pointer rounded-full flex items-center justify-center font-bold text-white" onClick={closeHandler}>X</div>
                        </div>
                        <div className="z-[100] absolute bottom-5 left-1/2 text-xl font-bold text-gray-500 -translate-x-1/2 flex items-center">
                            <div className="w-10 h-10 bg-white cursor-pointer rounded-full flex items-center justify-center" onClick={() => moveActiveImage(-1)}> {'<'} </div>
                            <div className="mx-5"><span className="text-white">{activeImage}</span> / {images.length + 1}</div>
                            <div className="w-10 h-10 bg-white cursor-pointer rounded-full flex items-center justify-center" onClick={() => moveActiveImage(1)}> {'>'} </div>
                        </div>
                    </div>
                ) : null
            }
            <div className="grid grid-cols-2 md:grid-cols-3 md:grid-rows-2 gap-1 md:gap-5 relative">
                <div className="col-span-2 md:col-span-1 md:row-span-2 rounded-lg overflow-hidden bg-gray-300 group transition-all cursor-pointer" onClick={() => setActiveImage(1)}>
                    <img src={poster} alt="Poster" className="object-cover w-full h-full group-hover:scale-150 transition-all" />
                </div>
                {
                    images?.map((image, index) => {
                        if (index < 4) {
                            return (
                                <div className="rounded-lg overflow-hidden bg-gray-300 h-[150px] md:h-[264px] cursor-pointer group transition-all" key={`product-image-${image.id}`} onClick={() => setActiveImage(index + 2)}>
                                    <img src={image.url} alt="" className="object-cover w-full h-full group-hover:scale-150 transition-all" />
                                </div>
                            )
                        }
                        return null;
                    })
                }
                {
                    images?.length > 4 ? (
                        <div className="absolute right-10 bottom-10 py-2 px-5 rounded-full bg-[rgba(0,0,0,.75)] text-white cursor-pointer" onClick={() => setActiveImage(1)}>
                            Lihat Semua
                        </div>
                    ): null
                }
            </div>
        </>
    )
}

export default ProductImages;