import { useState } from "react";
import GalleryCard from "./GalleryCard";
import GalleryModal from "./GalleryModal";

const GalleriesContainer = ({ galleries }) => {
    // const [showModal, setShowModal] = useState(false);
    const [activeImage, setActiveImage] = useState('');

    const closeHandler = () => {
        setActiveImage('');
    }

    return (
        <>
        {
            activeImage ? <GalleryModal closeHandler={closeHandler} image={activeImage} /> : null
        }
        <div className="grid md:grid-cols-3 gap-10">
            {
                galleries.map((gallery) => (
                    <GalleryCard {...gallery} key={`gallery-${gallery.id}`} clickHandler={setActiveImage} />
                ))
            }
        </div>
        </>
    )
}

export default GalleriesContainer;