import { Link } from 'react-router-dom';

const ArticleCard = ({ title, slug, description, cover, category, created_at }) => {
    return (
        <Link to={`/blog/${slug}`}>
            <div>
                <img src={cover} alt="" className='h-[200px] w-full mb-[16px] object-cover rounded-[12px]' />
                <div>
                    <small><strong className="uppercase">{category.name}</strong> . {created_at}</small>
                    <h3 className='my-[8px] font-bold text-[18px] text-ellipsis overflow-hidden line-clamp-2'>{title}</h3>
                    <p className='text-[16px] text-[#28354B] text-ellipsis overflow-hidden line-clamp-2'>{description}</p>
                </div>
            </div>
        </Link>
    )
}

export default ArticleCard;