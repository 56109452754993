const PriceCard = ({ price, type, selectedPackage, handler }) => {
    return (
        <div className={`border-2 ${selectedPackage === type ? 'border-[#336B89]' : ''} p-4 rounded-lg mb-3 cursor-pointer transition-all`} onClick={handler}>
            <h4 className="text-md font-bold">{type}</h4>
            {
                selectedPackage !== type ? null : (
                    <>
                        <h5 className="text-[#808998]">Harga Paket</h5>
                        <h3 className="text-2xl text-[#336B89] font-bold">Rp{price}</h3>
                    </>
                )
            }
        </div>
    )
}

export default PriceCard;