import ProductCard from "./ProductCard"

const Products = ({products}) => {
    return (
        <div className="grid md:grid-cols-3 gap-6">
            {
                products.map((product) => <ProductCard {...product} key={`${product.slug}`} />)
            }
        </div>
    )
}

export default Products;