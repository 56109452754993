import ProductApi from './ProductApi';
import GalleryApi from './GalleryApi';
import SliderApi from './SliderApi';
import TestimoniApi from './TestimoniApi';
import PartnerApi from './PartnerApi';
import SettingApi from './SettingApi';
import LegalityApi from './LegalityApi';
import DepartureApi from './DepartureApi';
import BenefitApi from './BenefitApi';
import SpecialProductApi from './SpecialProductApi';

export {
    ProductApi,
    GalleryApi,
    SliderApi,
    TestimoniApi,
    PartnerApi,
    SettingApi,
    LegalityApi,
    DepartureApi,
    BenefitApi,
    SpecialProductApi,
}