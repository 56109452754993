import CONFIG from "../config";

const BenefitApi = {
    endpoint: `${CONFIG.API_BASE_URL}/benefits`,
    async get() {
        const options = {};
        const request = new Request(`${this.endpoint}`, options);
        const response = await fetch(request);
        const resJson = response.json();

        return resJson;
    }
}

export default BenefitApi;