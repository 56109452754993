import { useEffect, useState } from 'react';
import { BenefitApi, SettingApi } from '../../api';

const Benefit = () => {
    const [totalDeparture, setTotalDeparture] = useState(0);
    const [totalDestination, setTotalDestination] = useState(0);
    const [benefits, setBenefits] = useState([]);
        const [benefitSection, setBenefitSection] = useState({});

    useEffect(() => {
        SettingApi.get().then((res) => {
            setTotalDeparture(res.total_departures);
            setTotalDestination(res.total_destinations);
            setBenefitSection(res.benefit_section);
        }).catch((error) => console.error(error));
        BenefitApi.get().then((res) => {
            setBenefits(res.data);
        }).catch((error) => console.error(error));
    }, []);

    return (
        <div className="flex flex-col md:flex-row my-[100px] md:my-[120px] justify-between items-center">
            <div className="relative w-full md:w-[445px] h-80 md:h-[593px] mb-10 md:mb-0">
                <div className="p-4 bg-[#F6F9FF] absolute -top-10 left-10 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold">{totalDeparture}</h4>
                    <p>Destinasi Wisata</p>
                </div>
                <div className="p-4 bg-[#F6F9FF] absolute -bottom-10 right-10 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold">{totalDestination}</h4>
                    <p>Keberangkatan</p>
                </div>
                <img src={benefitSection.image} alt=" " className="w-full h-full object-cover rounded-lg" />
            </div>

            <div className="md:w-[600px] md:pl-10 lg:p-0">
                <h2 className="text-4xl mt-5 md:text-[40px] font-bold">{benefitSection.title}</h2>
                <p className="my-5 md:my-[32px]">{benefitSection.description}</p>
                <div className="grid grid-cols-2 w-[100%]">
                    <div>
                    {
                        benefits.map((benefit, i) => {
                            if (i >= parseInt((benefits.length / 2))) return null;
                            return (
                                <div className="flex mb-6" key={`benefit-1-${i}`}>
                                    <span className="md:text-[24px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center font-bold rounded-full bg-[#FFBF00] mr-3">{i + 1}</span>
                                    <span className="w-[90%] text-[14px] md:text-[18px] font-semibold flex items-center">{benefit.benefit}</span>
                                </div>    
                            )
                        })
                    }
                    </div>
                    <div>
                    {
                        benefits.map((benefit, i) => {
                            if (i < parseInt((benefits.length / 2))) return null;
                            return (
                                <div className="flex mb-6" key={`benefit-2-${i}`}>
                                    <span className="md:text-[24px] w-[30px] h-[30px] md:w-[40px] md:h-[40px] flex items-center justify-center font-bold rounded-full bg-[#FFBF00] mr-3">{i + 1}</span>
                                    <span className="w-[90%] text-[14px] md:text-[18px] font-semibold flex items-center">{benefit.benefit}</span>
                                </div>    
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefit;