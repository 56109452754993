const ContactCard = ({ icon, title, description }) => {
    return (
        <div className="text-center">
            <div className="bg-[#F6F9FF] inline-block p-[12px] rounded-[16px]">
                <img src={icon} alt="" />
            </div>
            <h4 className="mt-[24px] mb-[18px] font-bold text-[18px]">{title}</h4>
            <p className="max-w-[300px] mx-auto">{description}</p>
        </div>
    )
}

export default ContactCard;