import patternImage from '../../assets/images/patter.png';
import { AboutSection, Jumbotron, Legalities, ProductServiceSection } from '../../components';
import { useEffect, useState } from 'react';
import { LegalityApi } from '../../api';

const About = () => {
    const [legalities, setLegalities] = useState([]);

    useEffect(() => {
        loadLegalities();
    }, []);

    const loadLegalities = () => {
        LegalityApi.get().then((res) => {
            setLegalities(res.data);
        }).catch((error) => console.log(error));
    }

    return (
        <>
            <Jumbotron background={patternImage} title="Tentang" />
            <div className='container mx-auto px-4 md:p-0'>
                <AboutSection />
                <ProductServiceSection />
            </div>
            <Legalities legalities={legalities} />
        </>
    )
}

export default About;