import { useEffect } from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import { SettingApi } from '../../api';

const CTA = () => {
    const [ctaSection, setCTASection] = useState({});

    useEffect(() => {
        SettingApi.get().then((res) => {
            setCTASection(res.cta_section);
        }).catch((error) => console.log(error));
    }, []);

    return (
        <div className='w-full h-[590px] flex items-center relative p-4 md:p-0'>
            <img src={ctaSection.image} alt=" " className='w-full h-full object-cover absolute left-0 right-0 -z-10' />
            <div className='container mx-auto'>
                <div className='max-w-[635px] mb-6 text-white'>
                    <h2 className='text-[40px] mb-3'>{ctaSection.title}</h2>
                    <p className='opacity-75'>{ctaSection.description}</p>
                </div>
                <Link to="/produk" className="px-[32px] py-[16px] bg-white rounded-lg font-bold inline-block">Pesan Sekarang</Link>
            </div>
        </div>
    )
}

export default CTA;