import { useEffect, useState } from "react";
import { SpecialProductApi } from "../../api";

const ProductServiceSection = () => {
    const [topProducts, setTopProducts] = useState([]);

    useEffect(() => {
        SpecialProductApi.get().then((res) => {
            setTopProducts(res.data);
        }).catch((error) => console.log(error));
    }, []);

    return (
        <div className="flex flex-col md:flex-row mb-[120px] md:items-center">
            <div className="md:pr-10 md:w-1/2 mb-3">
                <h2 className="mb-3 text-3xl font-bold place-items-center">Produk dan Layanan</h2>
                <p>Berbagai produk dan layanan khusus yang kami tawarkan, diantaranya yaitu:</p>
            </div>
            <div className="md:w-1/2">
                <ul className="columns-2 md:columns-1">
                    {
                        topProducts.map((product, index) => (
                            <li key={`special-product-${(index + 1)}`}><span className="font-bold text-gray-500">0{(index + 1)}.</span> {product.name}</li>
                        ))
                    }
                </ul>
            </div>
        </div>

    )
}

export default ProductServiceSection;