import CONFIG from "../config";

const TestimoniApi = {
    endpoint: `${CONFIG.API_BASE_URL}/testimonis`,
    async get(page) {
        const options = {};
        const request = new Request(`${this.endpoint}?page=${page}`, options);
        const response = await fetch(request);
        const resJson = response.json();

        return resJson;
    },
};

export default TestimoniApi;