import Navbar from "./Navbar";
import Hero from "./Hero";
import Tour from "./Tour";
import Products from "./Products";
import Clients from "./Clients";
import CTA from './CTA';
import Footer from "./Footer";
import Jumbotron from "./Jumbotron";
import GalleriesContainer from "./GalleriesContainer";
import AboutSection from "./AboutSection";
import ContactList from "./ContactList";
import Articles from "./Articles";
import ProductServiceSection from "./ProductServiceSection";
import Legalities from "./Legalities";
import FloatingButton from "./FloatingButton";
import PriceCard from "./PriceCard";
import CKEditor from "./CKEditor";
import Testimonials from "./Testimonials";

export {
    Navbar, Hero, Tour, Products, Clients, CTA, Footer, Jumbotron, GalleriesContainer, AboutSection,
    ContactList, Articles, ProductServiceSection, Legalities, FloatingButton, PriceCard, CKEditor, Testimonials
};