import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { FloatingButton, Footer, Navbar } from './components';
import ROUTES from './data/routes';
import { useEffect, useState } from 'react';

function App() {
  const location = useLocation();
  const [transparent, setTransparent] = useState(true); 

  useEffect(() => {
    const regexPathChecker = /\/produk\/*\w+|\/blog\/*\w+|\/kebijakan-privasi/;
    if (location.pathname.match(regexPathChecker)) {
      setTransparent(false);
    } else {
      setTransparent(true);
    }
  }, [location, transparent]);

  return (
    <>
    <Navbar transparent={transparent} activePath={location.pathname} />
    <Routes>
      {
        ROUTES.map((route, i) => (
          <Route path={route.link} element={route.element} key={`menu-${i}`} />
        ))
      }
    </Routes>
    <FloatingButton />
    <Footer />
    </>
  );
}

export default App;
