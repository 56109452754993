const Clients = ({ partners }) => {
    return (
        <div className="mb-[120px]">
            <h2 className="text-center text-[40px] mb-10 d:mb-[64px] font-bold">Mitra Kami</h2>
            <div className='grid grid-cols-2 md:grid-cols-4 place-items-center gap-5'>
                {partners.map((partner, i) => <img src={partner.logo} alt=" " key={`client-${i}`} />)}
            </div>
        </div>
    )
}

export default Clients;