import { useEffect, useState } from "react";
import { PartnerApi, ProductApi, SliderApi, TestimoniApi } from "../../api";
import { Clients, CTA, Hero, Testimonials, Tour } from "../../components";
import Benefit from "../../components/Benefit";
// import SearchBar from "../../components/SearchBar";
import Slideshow from "../../components/Slideshow";

const Home = () => {
    const [products, setProducts] = useState([]);
    const [slides, setSlides] = useState([]); 
    const [testimonis, setTestimonis] = useState([]);
    const [testimoniPage, setTestimoniPage] = useState(1);
    const [testimonisMeta, setTestimonisMeta] = useState({}); 
    const [partners, setPartners] = useState([]);

    useEffect(() => {
      loadProduct();
      loadSlides();
      loadPartners();
    }, []);

    useEffect(() => {
      loadTestimonis();
    }, [testimoniPage])

    const loadProduct = () => {
      ProductApi.get().then((res) => {
        setProducts(res.data.slice(0, 3));
      }).catch((error) => console.log(error));
    }

    const loadSlides = () => {
        SliderApi.get().then((res) => {
            setSlides(res.data);
        }).catch((error) => console.log(error));
    }

    const loadTestimonis = () => {
        TestimoniApi.get(testimoniPage).then((res) => {
            setTestimonis(res.data);
            setTestimonisMeta(res.meta);
        }).catch((error) => console.log(error));
    }

    const moveTestimoni = (n) => {
        let nextPage = testimoniPage + n;
        if (nextPage > testimonisMeta.last_page) nextPage = 1;
        else if (nextPage < 1) nextPage = testimonisMeta.last_page;
        setTestimoniPage(nextPage);
    }

    const loadPartners = () => {
        PartnerApi.get().then((res) => {
            setPartners(res.data);
        }).catch((error) => console.log(error));
    }
    

    return (
        <>
            <Hero>
                <Slideshow delay={5000} slides={slides} />
            </Hero>
            <div className="container relative mx-auto px-5 md:px-0">
                {/* <SearchBar /> */}
                <Benefit />
                <Tour products={products} />
                <Clients partners={partners} />
                <Testimonials testimonis={testimonis} currentPage={testimonisMeta.current_page} lastPage={testimonisMeta.last_page} moveHandler={moveTestimoni} />
            </div>
            <CTA />
        </>
    )
}

export default Home;