import patternImage from '../../assets/images/patter.png';
import addressDark from '../../assets/icons/address-dark.png';
import phoneDark from '../../assets/icons/phone-dark.png';
import mailDark from '../../assets/icons/mail-dark.png';
import timeDark from '../../assets/icons/time-dark.png';
import { ContactList, Jumbotron } from '../../components';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import './style.css';
import { useEffect, useState } from 'react';
import { SettingApi } from '../../api';

const Contact = () => {
    const [contacts, setContacts] = useState([]);
    // const [position, setPosition] = useState([]);

    useEffect(() => {
        SettingApi.get().then((res) => {
            setContacts([
                {
                    icon: addressDark,
                    title: 'ALAMAT',
                    description: res.address,
                },
                {
                    icon: phoneDark,
                    title: 'TELEPON & WHATSAPP',
                    description: `${res.phone} & ${res.whatsapp}`,
                },
                {
                    icon: mailDark,
                    title: 'EMAIL',
                    description: res.email,
                },
                {
                    icon: timeDark,
                    title: 'JAM OPERASIONAL',
                    description: 'Senin - Jumat, 9:00 - 17:00 WIB',
                },
            ]);
            // setPosition([
            //     res.latitude,
            //     res.longtitude,
            // ]);
        }).catch((error) => console.log(error));
    },[]);

    return (
        <>
            <Jumbotron background={patternImage} title="Kontak" />
            <div className='container mx-auto px-4 md:p-0'>
                <ContactList contacts={contacts} />
            </div>
            {/* <MapContainer center={[-6.3071031832168, 106.89991538465]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[-6.3071031832168, 106.89991538465]}>
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
                </Marker>
            </MapContainer> */}
        </>
    )
}

export default Contact;