const Slide = ({ title, description, image, translate }) => {
    return (
        <div className={`relative px-5 h-full w-full flex flex-col justify-center transition-all ${translate} flex-none`}>
            <div className="container mx-auto animate-show">
                <h1 className="text-4xl md:text-[56px] font-bold mb-[16px] text-white md:max-w-[635px] leading-[140%]">{title}</h1>
                <p className="text-white md:max-w-[635px] text-lg md:text-[20px] font-light">{description}</p>
            </div>
            <img src={image} alt=" " className="absolute top-0 left-0 -z-10 w-full h-full object-cover animate-show" />
            <div className="overlay absolute -z-[5] top-0 left-0 w-full h-full bg-gradient-to-t from-[#0A1931] to-[rgba(10,25,49,.4)]"></div>
        </div>
    )
}

export default Slide;