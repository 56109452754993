import { Products, Jumbotron } from "../../components";
import patternImage from '../../assets/images/patter.png';
import { useEffect, useState } from "react";
import { ProductApi } from "../../api";

const ProductService = () => {
    const [products, setProducts] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadProducts(currentPage);
    }, []);

    const loadProducts = () => {
        ProductApi.get(currentPage).then((res) => {
            if (res.data.length === 0) {
                setCompleted(true);
                return;
            }
            const newProducts = products.concat(res.data); 
            setProducts(newProducts);
            setCurrentPage(currentPage + 1);
        }).catch((error) => console.log(error));
    }
    

    return (
        <>
        <Jumbotron background={patternImage} title="Produk & Layanan" />
        <div className="container relative mx-auto px-5 md:px-0 my-[120px]">
            <Products products={products} />
            {
                !completed ? <button className="px-5 py-2 rounded-lg border mt-10 mx-auto block" onClick={() => loadProducts()}>Load More</button> : <></> 
            }
        </div>
        </>
    )
}

export default ProductService;