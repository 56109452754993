const GalleryCard = ({ image, description, category, clickHandler }) => {
    const handler = () => {
        clickHandler(image);
    }

    return (
        <div className="relative w-full bg-black h-[264px] rounded-[12px] overflow-hidden group cursor-pointer" onClick={handler}>
            <img src={image} alt="" className="w-full h-full object-cover" />
            <div className="absolute top-full left-0 transition-all w-full h-full flex flex-col items-center justify-center text-center bg-[rgba(0,0,0,.75)] text-white p-5 group-hover:top-0">
                <p className="mb-3">{description}</p>
                <h6 className="font-bold">{category}</h6>
            </div>
        </div>
    )
}

export default GalleryCard;