import { useEffect, useState } from 'react';
import { SettingApi } from '../../api';
import NavToggle from './NavbarToggle';
import NavMenu from './NavMenu';
import { Link } from 'react-router-dom';

const Navbar = ({ fixed, transparent, activePath }) => {
    const [navOpen, setNavOpen] = useState(false);
    const [logoDark, setLogoDark] = useState('');
    const [logoLight, setLogoLight] = useState('');

    useEffect(() => {
        SettingApi.get().then((res) => {
            setLogoDark(res.logo_dark);
            setLogoLight(res.logo_light);
        }).catch((error) => console.log(error));
    }, []);

    return (
        <nav className={`${fixed ? 'fixed ' : (transparent ? 'absolute' : 'bg-white')} top-0 z-50 w-full p-4 md:p-0`}>
            <div className="container flex flex-wrap justify-between items-center mx-auto py-[24px] md:px-0">
                <Link to="/">
                {
                    transparent ? <img src={logoLight} alt="logo" className="object-contain w-[150px]" /> : <img src={logoDark} alt="logo" className="object-contain w-[150px]" /> 
                }
                </Link>
                <NavToggle handler={() => setNavOpen(!navOpen)} transparent={transparent} />
                <NavMenu closeHandler={() => setNavOpen(false)} isOpen={navOpen} transparent={transparent} activePath={activePath} />
            </div>
        </nav>
    )
}

export default Navbar;