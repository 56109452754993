import patternImage from '../../assets/images/patter.png';
import { Jumbotron } from '../../components';
import starIcon from '../../assets/icons/product/star.png';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import {DepartureApi} from '../../api';

const Schedule = () => {
    const [departures, setDepartures] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadDepartures();
    }, [currentPage]);

    const loadDepartures = () => {
        DepartureApi.get(currentPage).then((res) => {
            setDepartures(res.data);
            if (res.data.length > 0) {
                setLastPage(res.meta.last_page ?? 1);
            }
        }).catch((error) => console.error(error));
    }

    const moveDeparure = (n) => {
        let nextPage = currentPage + n;
        if (nextPage > lastPage) nextPage = 1;
        else if (nextPage < 1) nextPage = lastPage;
        setCurrentPage(nextPage);
    }

    return (
        <>
            <Jumbotron background={patternImage} title="Keberangkatan" />
            <div className='my-[120px]'>
                <div className='container mx-auto overflow-x-auto'>
                    <table className='table-auto min-w-full text-left'>
                        <thead className='bg-[#F6F9FF]'>
                            <tr>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">PRODUK & LAYANAN</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Kategori</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Durasi</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Keberangkatan</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Hotel</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Maskapai</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap">Katerangan</th>
                                <th scope="col" className="py-3 px-6 whitespace-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                departures.map((departure, index) => (
                                    <tr key={`departure-${index}`}>
                                        <td className="py-4 px-6 whitespace-nowrap">{departure.name}</td>
                                        <td className="py-4 px-6 whitespace-nowrap">{departure.category}</td>
                                        <td className="py-4 px-6 whitespace-nowrap">{departure.duration} Hari</td>
                                        <td className="py-4 px-6 whitespace-nowrap">{departure.departure}</td>
                                        <td className="py-4 pl-6 pr-3 whitespace-nowrap">
                                            <div className='flex items-center space-x-14'>
                                                {
                                                    departure.hotels?.map((hotel) => (
                                                        <div className="flex items-center whitespace-nowrap" key={`hoetl-${hotel.id}`}>
                                                            {hotel.name} <img src={starIcon} alt="" /> {hotel.star}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td className="py-4 px-6 whitespace-nowrap">{departure.flight}</td>
                                        <td className="py-4 px-6 whitespace-nowrap">Tersedia</td>
                                        <td className="py-4 px-6 whitespace-nowrap">
                                            <Link to={`/produk/${departure.slug}`} className="font-bold">
                                                Detail
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="w-full flex items-center justify-center mt-3">
                    <button className="w-10 h-10 border rounded-lg" onClick={() => moveDeparure(-1)}>{`<`}</button>
                    <div className="mx-3">{currentPage} / {lastPage}</div>
                    <button className="w-10 h-10 border rounded-lg" onClick={() => moveDeparure(1)}>{`>`}</button>
                </div>
            </div>
        </>
    )
}

export default Schedule;