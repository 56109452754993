import { useEffect, useState } from 'react';
import { GalleryApi } from '../../api';
import patternImage from '../../assets/images/patter.png';
import { GalleriesContainer, Jumbotron } from '../../components';

const Gallery = () => {
    const [galleries, setGalleries] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadGalleries(currentPage);
    }, []);

    const loadGalleries = () => {
        GalleryApi.get(currentPage).then((res) => {
            if (res.data.length === 0) {
                setCompleted(true);
                return;
            }
            const newgalleries = galleries.concat(res.data); 
            setGalleries(newgalleries);
            setCurrentPage(currentPage + 1);
        }).catch((error) => console.log(error));
    }

    return (
        <>
            <Jumbotron background={patternImage} title="Gallery" />
            <div className='container mx-auto my-[120px] p-4 md:p-0'>
                <GalleriesContainer galleries={galleries} />
                {
                    !completed ? <button className="px-5 py-2 rounded-lg border mt-10 mx-auto block" onClick={() => loadGalleries()}>Load More</button> : <></> 
                }
            </div>
        </>
    )
}

export default Gallery;