import { useEffect, useState } from 'react';
import { SettingApi } from '../../api';

const AboutSection = () => {
    const [about, setAbout] = useState({});
    const [totalDeparture, setTotalDeparture] = useState(0);
    const [totalDestination, setTotalDestination] = useState(0);

    useEffect(() => {
        SettingApi.get().then((res) => {
            setAbout(res.about_section);
            setTotalDeparture(res.total_departures);
            setTotalDestination(res.total_destinations);
        }).catch((error) => console.log(error));
    }, []);

    return (
        <div className="my-[120px] flex flex-col md:flex-row justify-between items-center">
            <div className="relative w-full md:w-[445px] h-80 md:h-[593px] mb-8 md:m-0">
                <img src={about.image} alt=" " className="w-full h-full object-cover rounded-lg" />
            </div>
            <div className='max-w-[540px] md:px-5'>
                <h1 className='text-[40px] font-bold'>{about.title}</h1>
                <p className='my-[32px]'>{about.description}</p>
                <div className='grid grid-cols-2'>
                    <div>
                        <p className='text-[#808998]'>Destinasi Wisata</p>
                        <h4 className='font-bold text-[48px]'>{totalDestination}</h4>
                    </div>
                    <div>
                        <p className='text-[#808998]'>Keberangkatan</p>
                        <h4 className='font-bold text-[48px]'>{totalDeparture}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;