import { Link } from 'react-router-dom';
import Products from '../Products';

const Tour = ({ products }) => {
    return (
        <div className="flex justify-center items-center flex-col mb-[120px]">
            <h2 className="text-[40px] font-bold mb-16">Paket Wisata</h2>
            <Products products={products} />
            <Link to="/produk" className="px-[32px] py-[16px] mt-16 border border-1 rounded-lg font-bold">Lihat Semua</Link>
        </div>
    )
}

export default Tour;