/* eslint-disable react-hooks/exhaustive-deps */
import Slide from "./Slide";
import { useState, useRef, useEffect } from 'react';
import SlideIndicator from "./SlideIndocator";

const Slideshow = ({delay, slides}) => {
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }

    const dotHandler = (i) => {
        setIndex(i);
    }

    const arrowHandler = (move) => {
        const nextIndex = index + move;
        if (nextIndex === slides.length) return setIndex(0);
        else if (nextIndex < 0) return setIndex(slides.length - 1);
        setIndex(index + move);
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
            setIndex((prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1);
        }, delay);
    }, [index]);

    return (
        <div className={`absolute top-0 left-0 w-full overflow-hidden h-full`}>
            <SlideIndicator slides={slides} currentIndex={index} dotHandler={dotHandler} arrowHandler={arrowHandler} />
            <div className="slider relative flex h-full">
                {
                    slides.map((slide, i) => (
                        index === i && <Slide {...slide} key={i} /> 
                    ))
                }
            </div>
        </div>
    )
}

export default Slideshow;