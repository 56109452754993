import {Link} from 'react-router-dom';
import calendar2Icon from '../../../assets/icons/calendar-2.png';
import flightIcon from '../../../assets/icons/flight.png';
import hotelIcon from '../../../assets/icons/hotel.png';

const ProductCard = ({ id, slug, thumbnail, name, start_price, departure, hotel, flight, isPopuler }) => {
    return (
        <div className="rounded-[24px] overflow-hidden shadow-[0_12px_40px_rgba(0,0,0,.12)]">
            <img src={thumbnail} alt=" " className="h-[350px] w-full object-cover" />
            <div className="p-[16px]">
                <h4 className="text-[18px] mb-2">{name}</h4>
                <p className="text-sm text-gray-600">Mulai dari <span className="font-bold text-[20px] text-[#336B89]">{start_price}</span></p>
                <ul className="text-[16px] my-[24px]">
                    <li className='mb-2 flex'><img src={calendar2Icon} alt=" " className="mr-2" />{departure}</li>
                    {
                        hotel ? <li className='mb-2 flex'><img src={hotelIcon} alt=" " className="mr-2" />{hotel[0]?.name}</li> : null
                    }
                    {
                        flight ? <li className='flex'><img src={flightIcon} alt=" " className="mr-2" />{flight}</li> : null
                    }
                </ul>
                <Link to={`/produk/${slug}`} className="px-[20px] py-[12px] font-bold font-[16px] text-white inline-block bg-[#336B89] rounded-[12px]">Detail Paket</Link>
            </div>
        </div>
    )
}

export default ProductCard;