import { useEffect, useState } from 'react';
import ArticleApi from '../../api/ArticleApi';
import patternImage from '../../assets/images/patter.png';
import { Articles, Jumbotron } from '../../components';

const Blog = () => {
    const [articles, setArticles] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loadArticles(currentPage);
    }, []);

    const loadArticles = () => {
        ArticleApi.get(currentPage).then((res) => {
            if (res.data.length === 0) {
                setCompleted(true);
                return;
            }
            const newArticles = articles.concat(res.data); 
            setArticles(newArticles);
            setCurrentPage(currentPage + 1);
        }).catch((error) => console.log(error));
    }

    return (
        <>
            <Jumbotron background={patternImage} title="Blog" />
            <div className='my-[120px] container mx-auto px-4 md:p-0'>
                <Articles articles={articles} />
                {
                    !completed ? <button className="px-5 py-2 rounded-lg border mt-10 mx-auto block" onClick={() => loadArticles()}>Load More</button> : <></> 
                }
            </div>
        </>
    )
}

export default Blog;