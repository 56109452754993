const DotsIndicator = ({ slides, currentIndex, dotHandler }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            {
                slides.map((_, i) => (
                    <span className={`${i === currentIndex ? 'border-2  border-white ' : ''}w-5 h-5 m-1 rounded-full flex items-center justify-center cursor-pointer`} key={`dot-${i}`} onClick={() => dotHandler(i)}>
                        <span className={`${i !== currentIndex ? 'opacity-50 ' : ''}w-2 h-2 bg-white rounded-full`}></span>
                    </span>
                ))
            }
        </div>
    )
}

export default DotsIndicator;