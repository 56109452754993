const TestimoniCard = ({ profile, name, profetion, location, message }) => {
    return (
        <div className="p-[16px] rounded-[24px] bg-[#F6F9FF]">
            <p className="mb-[24px]">{message}</p>
            <div className="flex items-center">
                <img src={profile} alt=" " className="w-[48px] h-[48px] rounded-full" />
                <div className="ml-3">
                    <h6 className="font-bold">{name}</h6>
                    <p className="text-sm">{profetion}, {location}</p>
                </div>
            </div>
        </div>
    )
}

export default TestimoniCard;