import { Link } from 'react-router-dom';
import {ROUTES} from '../../../data';

const NavMenu = ({ closeHandler, isOpen, transparent, activePath }) => {
    return (
        <div className={`${isOpen ? '' : 'hidden'} w-full md:block md:w-auto`}>
            <ul className={`flex flex-col mt-4 p-4 md:p-0 space-y-2 md:space-y-0  md:flex-row md:space-x-7 md:mt-0 md:text-sm md:font-medium text-gray-500 ${transparent ? 'md:text-gray-300' : ''}  bg-white md:bg-transparent rounded-lg py-4 transition-all`}>
                {
                    ROUTES.map((route, i) => {
                        if (route.hidden) return null;
                        return (
                            <li key={`navmenu-${i}`}><Link to={route.link} className={activePath === route.link ? 'font-bold md:text-white' : ''} onClick={closeHandler}>{route.text}</Link></li>
                            // <li className="text-black md:text-white font-bold"><Link to='/'>Home</Link></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default NavMenu;