import { Link } from "react-router-dom"

const FooterMenu = ({ title, menu, columns }) => {
    return (
        <div className={`${columns > 1 ? '' : 'md:max-w-[255px] '}text-white mb-10 md:m-0`}>
            <h6 className="mb-[28px] font-[16px]"><strong>{title}</strong></h6>
            <ul className={`${ columns > 1 ? 'columns-' + columns + ' ' : '' }grayscale`}>
                {
                    menu.map((item, i) => (
                        <li key={item.link + i} className="mb-[8px] text-[14px]">
                            { item.link && item.link.includes('https') ? <a href={item.link} target="_blank" rel="noreferrer" className="flex items-start">{item.icon ? <img src={item.icon} alt="" className="mr-3 object-cover w-[24px] h-[24px]" /> : ''}{item.text}</a> : <Link to={item.link} className="flex items-start">{item.icon ? <img src={item.icon} alt="" className="mr-3 object-cover w-[24px] h-[24px]" /> : ''}{item.text}</Link>}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default FooterMenu;