const GalleryModal = ({ image, closeHandler }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[rgba(10,25,49,.75)] z-50" onClick={closeHandler}>
            <div className="relative w-full h-full p-4 md:p-[80px]">
                <img src={image} alt="" className="w-full h-full rounded-lg object-contain" />
            </div>
        </div>
    )
}

export default GalleryModal;